// s-menu

$(document).ready(function(){
    $('.s-menu__button').click(function(){
        $('.s-menu').toggleClass('s-menu--open');
        if ($('.s-menu').hasClass('s-menu--open')){
            $('body').css('overflow', 'hidden');
            window.scrollTo(0, 0);

        } else {
            $('body').css('overflow', 'auto');
        }
    });
});

// guestbook

$(document).ready(function(){
    if ($('.ioptions').length > 0){
       
        $('.ioptions').each(function(){
            var ioptions = $(this);
            var addition_a = ioptions.find('.addition a');
            addition_a.click(function(e){
                e.preventDefault();
                ioptions.find('.ioption').each(function(){
                    if (!$(this).hasClass('ioption--show')){
                        $(this).addClass('ioption--show');
                        if (ioptions.find('.ioption--show').length < 4){
                            return false;
                        } else {
                            addition_a.hide();
                        }
                    }
                });
            });
        });

    }
});